import Vue from 'vue';
import router from '@/router/index';

export default {
  async getPaymentOptions(params) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/paymentOption/list`
    if(process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `paygateway/paymentOption/list`
    }
    const config = {
      headers: {
        Authorization: `${Math.random().toString(36).substring(3, 8)}`,
        'Content-Type': 'application/json'
      }
    }
    const body = {
      mobile: params.mobile,
      merchantCode: params.MC,
      signature: params.sig,
      amount: params.amount,
      timestamp: params.timestamp
    }
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response)
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return { error: response.data.message };
      } else {
        return { error: "Sorry. Error verifying origin." };
      }
    } catch (error) {
      console.log(error);
      if(error && error.response && error.response.data) {
        console.log(error.response)
        var message = error
        if(error.response.data.message && error.response.data.message != "") {
          message = error.response.data.message
        } else if(error.response.data.error && error.response.data.error != "") {
          message = error.response.data.error
        }
        return { error: message};
      } else {
        return { error: error };
      }
    }
  },
  async getBalance(params) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/paymentMethod/balance`
    if(process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `paygateway/paymentMethod/balance`
    }
    const config = {
      headers: {
        Authorization: `${Math.random().toString(36).substring(3, 8)}`,
        'Content-Type': 'application/json'
      }
    }
    const body = {
      mobileNumber: params.mobile,
      paymentCode: params.paymentCode,
      paymentOptions: params.paymentOptions,
      merchantCode: params.merchantCode,
      signature: params.signature,
      amount: params.amount,
      amountOptions: params.amountOptions,
      chargeReference: params.merchantReference,
      timestamp: params.timestamp
    }
    try {
      const response = await Vue.axios.post(url, body, config);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return { error: response.data.message };
      } else {
        return { error: "Sorry. Error verifying origin." };
      }
    } catch (error) {
      console.log(error);
      if(error && error.response && error.response.data) {
        console.log(error.response)
        var message = error
        if(error.response.data.message && error.response.data.message != "") {
          message = error.response.data.message
        } else if(error.response.data.error && error.response.data.error != "") {
          message = error.response.data.error
        }
        return { error: message};
      } else {
        return { error: error };
      }
    }
  },
  async getBalanceV2(params) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/paywallet/balance`
    if(process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `paygateway/paywallet/balance`
    }
    const config = {
      headers: {
        Authorization: `${Math.random().toString(36).substring(3, 8)}`,
        'Content-Type': 'application/json'
      }
    }
    const body = {
      mobile: params.mobile,
      paymentCode: params.paymentCode,
      merchantCode: params.merchantCode,
      signature: params.signature,
      amount: params.amount,
      timestamp: params.timestamp
    }
    try {
      const response = await Vue.axios.post(url, body, config);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return { error: response.data.message };
      } else {
        return { error: "Sorry. Error verifying origin." };
      }
    } catch (error) {
      console.log(error);
      if(error && error.response && error.response.data) {
        console.log(error.response)
        var message = error
        if(error.response.data.message && error.response.data.message != "") {
          message = error.response.data.message
        } else if(error.response.data.error && error.response.data.error != "") {
          message = error.response.data.error
        }
        return { error: message};
      } else {
        return { error: error };
      }
    }
  },
  async charge(params) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/paymentMethod/charge`
    if(process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `paygateway/paymentMethod/charge`
    }
    const config = {
      headers: {
        Authorization: `${Math.random().toString(36).substring(3, 8)}`,
        'Content-Type': 'application/json'
      }
    }
    const token = params.mobile+":"+params.pin
    const body = {
      mobileNumber: params.mobile,
      paymentCode: params.paymentCode,
      paymentOptions: params.paymentOptions,
      merchantCode: params.merchantCode,
      signature: params.signature,
      amount: params.amount,
      amountOptions: params.amountOptions,
      chargeReference: params.merchantReference,
      token: `${btoa(token)}`,
      timestamp: params.timestamp
    }

      return Vue.axios.post(url, body, config)
      .then((response) => {
        if (response && response.data && response.status == 200) {
          /**
           * message
           * statusCode
           * successUrl
           * updatedBalance
           */
          return response.data;
        } else if (response && response.data && response.data.message) {
          return { error: response.data.message, failUrl: response.data.failUrl };
        } else {
          return { error: "Sorry, error in processing your payment.", failUrl: response.data.failUrl };
        }
      })
      .catch((error) => {
        console.log(error)
        if(error && error.response && error.response.data) {
          console.log(error.response)
          return { error: error.response.data.message, failUrl: error.response.data.failUrl };
        }
        return { error: error };
      })
  },
  async chargeV2(params) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/paymentMethod/v2/charge`
    if(process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `paygateway/paymentMethod/v2/charge`
    }
    const config = {
      headers: {
        Authorization: `${Math.random().toString(36).substring(3, 8)}`,
        'Content-Type': 'application/json'
      }
    }

    const body = {
      mobileNumber: params.mobile,
      paymentCode: params.paymentCode,
      paymentOptions: params.paymentOptions,
      merchantCode: params.merchantCode,
      signature: params.signature,
      amount: params.amount,
      amountOptions: params.amountOptions,
      chargeReference: params.merchantReference,
      timestamp: params.timestamp,
      accountNumber: params.accountNumber,
      accountName: params.accountName,
      transactionType: params.transactionType,
      email: params.email,
      token: params.token,
    }

      return Vue.axios.post(url, body, config)
      .then((response) => {
        if (response && response.data && response.status == 200) {
          /**
           * message
           * statusCode
           * successUrl
           * updatedBalance
           */
          return response.data;
        } else if (response && response.data && response.data.message) {
          return { error: response.data.message, failUrl: response.data.failUrl };
        } else {
          return { error: "Sorry, error in processing your payment.", failUrl: response.data.failUrl };
        }
      })
      .catch((error) => {
        console.log(error)
        if(error && error.response && error.response.data) {
          console.log(error.response)
          return { error: error.response.data.message, failUrl: error.response.data.failUrl };
        }
        return { error: error };
      })
  },
  async getPaymongoPaymentIntent(params) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/paymentOption/paymentIntent`
    if(process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `paygateway/paymentOption/paymentIntent`
    }
    const config = {
      headers: {
        Authorization: `${Math.random().toString(36).substring(3, 8)}`,
        'Content-Type': 'application/json'
      }
    }

    const body = {
      amount: 10000.00
    }

      return Vue.axios.post(url, body, config)
      .then((response) => {
        if (response && response.data && response.status == 201) {
          return response.data;
        } else if (response && response.data && response.data.message) {
          return { error: response.data.message };
        } else {
          return { error: "Sorry. Error in selecting payment method." };
        }
      })
      .catch((error) => {
        console.log(error);
        if(error && error.response && error.response.data) {
          console.log(error.response)
          var message = error
          if(error.response.data.message && error.response.data.message != "") {
            message = error.response.data.message
          } else if(error.response.data.error && error.response.data.error != "") {
            message = error.response.data.error
          } else if(error.response.data.errors && error.response.data.errors.errors && error.response.data.errors.errors.length > 0) {
            console.log(error.response.data.errors.errors)
            message = "ERRORS:<br/>";
            for(var i=0; i<error.response.data.errors.errors.length; i++) {
              message = `${message} <br/>[${i+1}] ${error.response.data.errors.errors[i].detail}`
            }
          }
          return { error: message};
        } else {
          return { error: error };
        }
      })
  },
  async getPaymentMethod() {
    
  },

  //callback API for landbank integration --

  async getCallbackUrl(params) {
     /**Use when CORS issue is resolved, directly communicate to server without proxy*/
     var url = `${process.env.VUE_APP_PAY_GATEWAY_URL}/landbank/callback`
     if(process.env.VUE_APP_IS_PROXY === "true") {
       /**Use when CORS issue persists, and use proxy*/
       url = `paygateway/landbank/callback`
     }
     const config = {
       headers: {
         Authorization: `${Math.random().toString(36).substring(3, 8)}`,
         'Content-Type': 'application/json'
       }
     }
 
     const body = {
        LBPRefNum: params.LBPRefNum,
        MerchantRefNum: params.MerchantRefNum,
        LBPConfDate: params.LBPConfDate,
        TrxnAmount: params.TrxnAmount,
        LBPConfNum: params.LBPConfNum,
        LBPConfDate: params.LBPConfDate,
        ErrorCode: params.ErrorCode,
        Checksum: params.Checksum,
        CRN: params.MerchantRefNum
     }
 
       return Vue.axios.post(url, body, config)
       .then((response) => {
         if (response && response.data && response.status == 200) {
           /**
            * message
            * statusCode
            * successUrl
            * updatedBalance
            */
           return response.data;
         } else if (response && response.data && response.data.message) {
           return { error: response.data.message, failUrl: response.data.failUrl };
         } else {
           return { error: "Sorry, error in processing your payment.", failUrl: response.data.failUrl };
         }
       })
       .catch((error) => {
         console.log(error)
         if(error && error.response && error.response.data) {
           console.log(error.response)
           return { error: error.response.data.message, failUrl: error.response.data.failUrl };
         }
         return { error: error };
       })
  }

  //---

}
